.visitor-form-container{
    padding: 24px;
    padding-bottom: 4px;
}
.visitor-items-c{
    display: flex;
    width: 100%;
}
.visitor-items-h{
    font-size: 24px;
    margin-bottom: 4px;
    font-weight: 700;
    color: #054054;
}
.react-otp-l{
    margin-top: 16px;
}
.react-otp-l input{
    margin-top: 12px;
    border-bottom: 2px solid green!important;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-right: 10px;
    height: 28px!important;
    width: 36px!important;
}
.visitor-items-first, .visitor-items-sec{
    flex: 1;
    margin-top: 24px;
}
.visitor-items-sec{
    padding-left: 16px;
}
.visitor-social-links{
    display: flex;
}
.visitor-social-links .MuiCheckbox-colorPrimary{
    padding: 4px;
    display: flex;
}
.visitor-link-container>div{
    margin-top: 16px;
}
.v-ad-area{
    flex: .3;
    max-width: 320px;
    padding-left: 28px;
}
.rd-title-select{
    border: 1px solid #ccc;
    border-radius: 6px;
}

.v-form-area{
    flex: .7;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    overflow: hidden
}
.v-form-ad-c{
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 12px auto
}
.my-reg-title {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 76px;
    background-color: #ff5722;
    color: white;
    font-size: 26px;
    font-weight: bold;
  }
@media only screen and (max-width: 800px) {
    .visitor-items-c {
        flex-direction: column;
    }
    .visitor-items-first, .visitor-items-sec {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .visitor-items-sec {
        padding-left: 0;
    }
    .visitor-social-links {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 600px) {
    .visitor-items-h {
        font-size: 20px;
    }
    .my-reg-title{
        height: 56px;
    font-size: 24px;
    margin-top: 16px;
    }
    .v-form-area, .v-ad-area{
        flex: 1;
        padding: 0;
        max-width: initial;
        margin: 2px 16px;
    }
    .v-ad-area{
        display: none;
    }
    .v-form-ad-c{
        flex-direction: column;
    }
    .visitor-form-container {
        padding: 16px;
    }
}

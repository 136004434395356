* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --btn-bg-color: #e1ebfd;
  --background-default: #f3f3f9;
  --dark-color: #222B40;
  --title-color:#848484;
  --sidebar-title-color:#F5F5F5;
  --sidebar-title-active-color: #00A2D9;
  --sidebar-tab-active-color: #00A2D9;
}


